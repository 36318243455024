import {createStore} from "vuex";
import moment from "moment";
import {store} from "core-js/internals/reflect-metadata";

export default createStore({
    state: {
        users: [],
        total: 0,
        page: 1,
        token: false,
        userName: '',
        clients: [],
        client_id: null,
    },
    getters: {
        getUsers: (state) => state.users,
        getLogin: (state) => state.token
    },
    actions: {
        async fetchUsers({dispatch, commit}, option) {
            if(this.client_id && option.id === undefined){
                dispatch('getClient', {id:this.client_id});
            }else {
                const page = option.page;
                store.page = option.page;
                let search = null;
                if (option?.search) {
                    search = option.search;
                }
                try {
                    const request = await fetch('https://panel.amogeneration.ru/api/get.clients', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ page: page, search: search })
                    });
                    const data = await request.json();
                    let total = 1;
                    let users = [];
                    if (data?.list && data?.list?.length) {
                        users = [...data.list].map((v) => {
                            let data_uninstall = v.data_uninstall;
                            let date_end_paid = v.date_end_paid;
                            if (data_uninstall === '0') {
                                data_uninstall = '';
                            } else {
                                data_uninstall = moment(v.data_uninstall * 1000).format('DD.MM.YYYY');
                            }
                            if (date_end_paid === '0') {
                                date_end_paid = '';
                            } else {
                                date_end_paid = moment(v.date_end_paid * 1000).format('DD.MM.YYYY');
                            }
                            return {
                                id: v.id,
                                amo_id: v.amo_id,
                                client_id: v.client_id,
                                subdomain: v.subdomain,
                                name: v.name,
                                amo_name: v.amo_name,
                                widget: v.widget,
                                email: v.email,
                                phone: v.phone,
                                data_install: moment(v.data_install * 1000).format('DD.MM.YYYY'),
                                data_uninstall: data_uninstall,
                                date_end_demo: moment(v.date_end_demo * 1000).format('DD.MM.YYYY'),
                                date_end_paid: date_end_paid,
                                type_activate: v.type_activate
                            }
                        });
                    }
                    if (data?.total) {
                        total = data.total;
                    }
                    commit("SET_USERS", users);
                    commit("SET_TOTAL", total);
                    if (data?.clients) {
                        commit("SET_CLIENTS", data.clients ?? []);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async updateClient({commit}, option){
            if(option.client.date_end_demo){
                option.client.date_end_demo = moment(option.client.date_end_demo, 'DD.MM.YYYY').format('YYYY-MM-DD')
            }
            if(option.client.date_end_paid){
                option.client.date_end_paid = moment(option.client.date_end_paid, 'DD.MM.YYYY').format('YYYY-MM-DD')
            }
            const request = await fetch('https://panel.amogeneration.ru/api/update.client',{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(option)
            });
            const data = await request.text();
            console.debug(data);
            commit('SET_TOTAL1', 0);
        },
        async checkLogin({commit}, option){
            const request = await fetch('https://panel.amogeneration.ru/api/check.login',{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(option.login)
            });
            const data = await request.json();
            if(data?.success && data.success){
                commit('SET_LOGIN', data.success);
            }
        },
        async getLogin({commit}, option){
            const request = await fetch('https://panel.amogeneration.ru/api/get.login',{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(option)
            });
            const data = await request.json();
            if(data?.success && data.success){
                commit('SET_USER_NAME', data.success);
            }
        },
        async getClient({commit}, option){
            this.client_id = option.id;
            try {
                const request = await fetch('https://panel.amogeneration.ru/api/get.client',{
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({id:option.id})
                });
                const data = await request.json();
                let total = 1;
                let users = [];
                if(data?.list && data?.list?.length){
                    users = [...data.list].map((v)=>{
                        let data_uninstall = v.data_uninstall;
                        let date_end_paid = v.date_end_paid;
                        if(data_uninstall === '0'){
                            data_uninstall = '';
                        }else {
                            data_uninstall = moment(v.data_uninstall * 1000).format('DD.MM.YYYY');
                        }
                        if(date_end_paid === '0'){
                            date_end_paid = '';
                        }else {
                            date_end_paid = moment(v.date_end_paid * 1000).format('DD.MM.YYYY');
                        }
                        return {
                            id: v.id,
                            amo_id: v.amo_id,
                            client_id: v.client_id,
                            subdomain: v.subdomain,
                            name: v.name,
                            amo_name: v.amo_name,
                            widget: v.widget,
                            email: v.email,
                            phone: v.phone,
                            data_install: moment(v.data_install * 1000).format('DD.MM.YYYY'),
                            data_uninstall: data_uninstall,
                            date_end_demo: moment(v.date_end_demo * 1000).format('DD.MM.YYYY'),
                            date_end_paid: date_end_paid,
                            type_activate: v.type_activate
                        }
                    });
                }
                if(data?.total){
                    total = data.total;
                }
                commit("SET_USERS", users);
                commit("SET_TOTAL", total);
            } catch (error) {
                console.log(error);
            }
        }
    },
    mutations: {
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_TOTAL(state, total){
            state.total = total;
        },
        SET_LOGIN(state, token){
            state.token = token;
        },
        SET_USER_NAME(state, name){
            state.userName = name;
        },
        SET_CLIENTS(state, list){
            state.clients = list;
        },
        CLEAR_CLIENT_ID(state, data){

            console.debug('CLEAR_CLIENT_ID ', data);

            state.client_id = data;
        }
    },
});