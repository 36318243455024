import { createWebHistory, createRouter } from "vue-router";
import Widgets from '../views/WidgetsList.vue';
import Clients from '../views/ClientsList.vue';

const routes = [
    {
        path: "/",
        name: "Widgets",
        component: Clients,
    },
    {
        path: "/clients",
        name: "Clients",
        component: Widgets,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;