<template>
    <div class="search-block">
        <el-input
            v-model="search"
            placeholder="Поиск"
            :prefix-icon="Search"
            @input="searchClients"
        />
    </div>
    <el-table :data="getUsers" style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column label="ID аккаунт" prop="amo_id" width="120" />
        <el-table-column label="Домен" prop="subdomain"/>
        <el-table-column label="Клиент" prop="amo_name"/>
        <el-table-column label="Виджет">
            <template #default="scope">
                {{ this.widgets[scope.row.widget] }}
            </template>
        </el-table-column>
        <el-table-column label="Дата" prop="data_install"/>
        <el-table-column label="Тип" width="110">
            <template #default="scope">
                <div v-if="scope.row.type_activate">Подписка</div>
                <div v-else>Демо</div>
            </template>
        </el-table-column>
        <el-table-column align="right">
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
                    Редактировать
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="getTotal" :page-size="25"  @current-change="setPage"/>
    </div>
    <el-dialog
            v-model="dialogVisible"
            title="Редактирование аккаунта"
            width="40%"
            :before-close="handleClose">
        <div id="content">
            <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Имя
                </span>
                <el-input
                        v-model="clientItem.name"
                        class="w-50 m-2"
                        placeholder="Имя"
                        :prefix-icon="User"
                        @input="changeFrom()"
                />
            </el-row>
            <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Телефон
                </span>
                <el-input
                        v-model="clientItem.phone"
                        class="w-50 m-2"
                        placeholder="Имя"
                        :prefix-icon="PhoneFilled"
                        @input="changeFrom()"
                />
            </el-row>
            <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    E-mail
                </span>
                <el-input
                        v-model="clientItem.email"
                        class="w-50 m-2"
                        placeholder="Имя"
                        :prefix-icon="Message"
                        @input="changeFrom()"
                />
            </el-row>
            <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Дата установки
                </span>
                <el-date-picker
                        v-model="clientItem.data_install"
                        format="DD.MM.YYYY"
                        type="date"
                        class="w-50 m-2"
                        disabled
                        :prefix-icon="Calendar"
                        @input="changeFrom()"
                />
            </el-row>
            <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Дата деинсталляции
                </span>
                <el-date-picker
                        v-model="clientItem.data_uninstall"
                        format="DD.MM.YYYY"
                        type="date"
                        class="w-50 m-2"
                        disabled
                        :prefix-icon="Calendar"
                        @input="changeFrom()"
                />
            </el-row>
            <hr>
            <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Дата окночания демо периода
                </span>
                <el-date-picker
                    v-model="clientItem.date_end_demo"
                    value-format="DD.MM.YYYY"
                    format="DD.MM.YYYY"
                    type="date"
                    class="w-50 m-2"
                    :prefix-icon="Calendar"
                    @change="updateDemoDate"
                />
            </el-row>
            <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Платная подписка
                </span>
                <el-switch
                    v-model="clientItem.type_activate"
                    class="ml-2"
                    active-text="Платная"
                    inactive-text="Бесплатная"
                    @change="updatePaid"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                />
            </el-row>
            <el-row v-show="clientItem.type_activate" :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Дата окночания подписки
                </span>
                <el-date-picker
                    v-model="clientItem.date_end_paid"
                    value-format="DD.MM.YYYY"
                    format="DD.MM.YYYY"
                    type="date"
                    class="w-50 m-2"
                    :prefix-icon="Calendar"
                    @change="updatePaidDate"
                />
            </el-row>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">Отмена</el-button>
            <el-button :disabled="saveEnable" type="primary" @click="saveClient">
              Сохранить
            </el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script lang="js">
import {onMounted} from 'vue'
import store from "@/components/store/store";
import {Calendar, Message, PhoneFilled, Search, User} from "@element-plus/icons-vue";


export default {
    name: 'ClientsList',
    data() {
        return {
            search: '',
            userList: [],
            pageTotal: 0,
            dialogVisible: false,
            saveEnable: true,
            clientItem: {
                id: 0,
                amo_id: 0,
                subdomain: '',
                name: '',
                email: '',
                phone: '',
                data_install: '',
                data_uninstall: '',
                date_end_demo: '',
                date_end_paid: '',
                type_activate: false
            },
            clientOriginal: null,
            currentPage: 1,
            widgets:{'amogen_checklist':'Чек-лист','amogen_checklist_en':'Чек-лист[en]'}
        }
    },
    computed: {
        Search() {
            return Search
        },
        Calendar() {
            return Calendar
        },
        Message() {
            return Message
        },
        PhoneFilled() {
            return PhoneFilled
        },
        User() {
            return User
        },
        getUsers() {
            return store.state.users;
        },
        getTotal() {
            return store.state.total;
        }
    },
    setup() {
        onMounted(() => {
            store.dispatch('fetchUsers', {page: 1});
        });
    },
    methods: {
        tableRowClassName(row){
            if (row.row.type_activate) {
                return 'paid-row'
            } else{
                return 'demo-row'
            }
        },
        handleClose() {
            this.dialogVisible = false;
        },
        handleEdit(index, client) {
            this.dialogVisible = true;
            setTimeout(()=>{
                this.clientItem = JSON.parse(JSON.stringify(client));
                this.clientOriginal = JSON.parse(JSON.stringify(client));
            }, 300);
        },
        setPage(val) {
            this.currentPage = val;
            store.dispatch('fetchUsers', {page: val});
        },
        changeFrom() {
            if(JSON.stringify(this.clientItem) !== JSON.stringify(this.clientOriginal)){
                this.saveEnable = !(this.clientItem.type_activate === false && this.clientItem.date_end_demo?.length && this.clientItem.name.length
                    && this.clientItem.phone.length && this.clientItem.email.length ||
                    this.clientItem.type_activate === true && this.clientItem.date_end_paid?.length && this.clientItem.name.length
                    && this.clientItem.phone.length && this.clientItem.email.length);
            }else {
                this.saveEnable = true;
            }
        },
        updateDemoDate(data){
            this.clientItem.date_end_demo = data;
            this.changeFrom();
        },
        updatePaidDate(data){
            this.clientItem.date_end_paid = data;
            this.changeFrom();
        },
        updatePaid(bool){
            this.clientItem.type_activate = bool;
            this.changeFrom();
        },
        saveClient(){
            this.dialogVisible = false;
            this.saveEnable = false;
            store.dispatch('updateClient', {
                client: this.clientItem
            }).then(()=>{
                store.dispatch('fetchUsers', {page: this.currentPage});
            });
        },
        searchClients(value){
            if(value.length > 2){
                store.dispatch('fetchUsers', {
                    client: this.clientItem,
                    search: value,
                });
            }else if(!value.length){
                store.dispatch('fetchUsers', {page: 1});
            }
        }
    }
}
</script>
<style>
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.items-center {
    align-items: center;
}

.inline-flex {
    display: inline-flex;
}

.text-gray-600 {
    --un-text-opacity: 1;
    color: rgba(75, 85, 99, var(--un-text-opacity));
}

.w-35 {
    width: 14.75rem;
}

.ml-3 {
    margin-left: 0.75rem;
}
.demo-row {
    --el-table-tr-bg-color: var( --el-fill-color-lighter);
}
.paid-row{
    --el-table-tr-bg-color: var(--el-color-success-light-9)
}
.el-table .cell {
    word-break: normal;
}
.search-block{
    display: flex;
}
</style>