<template>
    <el-row :gutter="20" v-loading="isLoading">
        <el-col :span="12" :offset="6">
            <el-col :span="14" :offset="4">
                <el-col :gutter="20" :align="'middle'">
                    <el-input
                            class="m-2 w-75"
                            placeholder="Логин"
                            :prefix-icon="User"
                            autocomplete="off"
                            v-model="user.login"
                            ref="login"
                    />
                </el-col>
                <el-col :gutter="20">
                    <el-input
                            type="password"
                            class="m-2 w-75"
                            show-password
                            placeholder="Пароль"
                            :prefix-icon="More"
                            autocomplete="off"
                            v-model="user.password"
                            ref="password"
                    />
                </el-col>
                <el-alert v-show="isError" title="Неправильный логин или пароль" type="error" show-icon />
                <el-col :gutter="20">
                    <el-button @click="saveLogin()" type="primary" plain>Вход</el-button>
                </el-col>
            </el-col>
        </el-col>
    </el-row>
</template>
<script lang="js">
import {More, User} from "@element-plus/icons-vue";
import store from "@/components/store/store";

export default {
    name: 'LoginBlock',
    computed: {
        More() {
            return More
        },
        User() {
            return User
        },
        setLogin(){
            return store.getters.getLogin;
        }
    },
    data() {
        return {
            user: {
                login: '',
                password: ''
            },
            isLoading: false,
            isError: false
        }
    },
    methods: {
        saveLogin(){
            this.isLoading = true;
            if(this.user.login.length && this.user.password.length){
                store.dispatch('checkLogin', {login: this.user}).then(()=>{
                    if(this.setLogin){
                        this.$emit('callback', this.setLogin);
                    }else {
                        this.isError = true;
                        setTimeout(()=>{
                            this.isError = false;
                        }, 1500)
                    }
                    this.isLoading = false;
                });
            }
        }
    }
}

</script>